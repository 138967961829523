import { http } from '@/http/axios.js'
import axios from 'axios'
import { getToken } from '@/utils/auth'
import { showScreenLoading, hideScreenLoading } from '@/http/loading.js'
import store from '@/store/index.js'
// 列表
export function getListAPI(data) {
  return http({
    url: `/api/company/user/list`,
    method: 'post',
    data
  })
}
// bill
export function getBillListAPI(params) {
  return http({
    url: `/manage/user/balance/record/page`,
    method: 'get',
    params
  })
}

export function getDetailAPI(id) {
  return http({
    url: `/api/company/user/detail`,
    method: 'get',
    params: { id }
  })
}
export function changeStatusAPI(data) {
  return http({
    url: `/api/company/user/updateLock`,
    method: 'post',
    data
  })
}
export function delAPI(id) {
  return http({
    url: `/api/company/user/del`,
    method: 'post',
    data: { id }
  })
}
// 全部导出
export function DownloadAPI(params) {
  showScreenLoading()
  axios({
    method: 'get',
    url: '/api/api/company/user/download',
    responseType: 'blob',
    params,
    headers: {
      Authorization: getToken() || '',
      Appid: store.state.user.appid
    }
  })
    .then(async (res) => {
      hideScreenLoading()
      let data = res.data
      // if (!data || data.type !== 'application/vnd.ms-excel') {
      //   let response = await new Response(data).text()
      //   response = JSON.parse(response)
      //   Message.error(response?.message || '导出失败')
      //   return
      // }
      let url = window.URL.createObjectURL(new Blob([data]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      let filename = `用户列表.xlsx`
      a.setAttribute('download', decodeURIComponent(filename))
      document.body.appendChild(a)
      a.click() //执行下载
      window.URL.revokeObjectURL(a.href) //释放url
      document.body.removeChild(a) //释放标签
    })
    .catch((error) => {
      hideScreenLoading()
    })
}
