<template>
    <div class="wrapper">
        <div class="user-box">
            <el-image style="width: 60px; height: 60px; border-radius: 5px" :src="detail.face"
                      :preview-src-list="[detail.face]" fit="contain"></el-image>
            <div class="user">
                <div class="name">{{ detail.nickname }}</div>
                
                <div class="mobile">{{ detail.mobile }}</div>
            </div>
        </div>
        <!-- tab -->
        <div class="tabs-box">
            <el-tabs v-model="activeName">
                <el-tab-pane label="基本信息" name="basicInfo">
                    <el-descriptions class="margin-top" :column="2" :size="size" border>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                昵称
                            </template>
                            {{ detail.nickname }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                手机号
                            </template>
                            {{ detail.mobile }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                真实姓名
                            </template>
                            {{ detail.realname }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i></i>
                                性别
                            </template>
                            {{ detail.sexName }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <template slot="label"> 年龄</template>
                            {{ detail.age }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 身高</template>
                            {{ detail.height }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <template slot="label"> 星座</template>
                            {{ detail.constellation }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 喜欢类型</template>
                            {{ detail.likeType }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <template slot="label"> 魅力值</template>
                            {{ detail.meiLiZhi }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 活跃值</template>
                            {{ detail.huoYueZhi }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <template slot="label"> 爽约次数</template>
                            {{ detail.huoYueZhi }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 迟到次数</template>
                            {{ detail.shuangYueCount }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <template slot="label"> 注册日期</template>
                            {{ detail.regDay }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> Im 账号id</template>
                            {{ detail.imUserId }}
                        </el-descriptions-item>

                        <el-descriptions-item>
                            <template slot="label"> 身份级别</template>
                            {{ detail.levelDsc }}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label"> 用户权益到期时间</template>
                            {{ detail.userPrivilegeExpire }}
                        </el-descriptions-item>
                    </el-descriptions>
                </el-tab-pane>

                <el-tab-pane label="邀请人信息" name="inviter">
                    <el-descriptions v-if="detail.inviterUser">
                        <el-descriptions-item label="姓名">{{ detail.inviterUser.realname }}</el-descriptions-item>
                        <el-descriptions-item label="手机号">{{ detail.inviterUser.mobile }}</el-descriptions-item>
                        <el-descriptions-item label="头像">
                            <el-image
                                style="width: 60px; height: 60px; border-radius: 5px"
                                :src="detail.inviterUser.face"
                                fit="contain"
                                :preview-src-list="[detail.inviterUser.face]"
                            ></el-image>
                        </el-descriptions-item>
                        <el-descriptions-item label="等级">
                            <el-tag size="small" type="primary">{{ detail.levelDsc }}</el-tag>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-tab-pane>
                <el-tab-pane label="钱包信息" name="balance">
                    <div v-if="detail.userBalance" class="balance animate__animated animate__faster animate__zoomIn">
                        <div class="item">
                            <div class="label">萝卜币</div>
                            <div class="value">{{ detail.userBalance.totalCoin }}</div>
                        </div>
                        <div class="item">
                            <div class="label">可提现</div>
                            <div class="value">{{ detail.userBalance.availableCoin }}</div>
                        </div>
                        <div class="item">
                            <div class="label">冻结</div>
                            <div class="value">{{ detail.userBalance.freezeCoin }}</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="银行卡" name="card">
                    <div class="card-list animate__animated animate__faster animate__zoomIn">
                        <div class="card" v-for="(item, i) in detail.userBankAccountList" :key="i">
                            <div class="bank">{{ item.bankName }}</div>
                            <div class="name">
                                <span>{{ item.holderName }}</span>
                                <span> <span style="font-size: 14px">默认：</span>{{
                                    item.isDefault ? '是' : '否'
                                    }} </span>
                            </div>
                            <div class="no">{{ item.cardNumber }}</div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="用户流水" name="bill">
                    <el-table :data="list" style="width: 100%">
                        <el-table-column prop="typename" label="类型" min-width="150"></el-table-column>
                        <el-table-column prop="beforeCoin" label="使用前萝卜币" width="100"></el-table-column>
                        <el-table-column prop="afterCoin" label="使用后萝卜币" width="100"></el-table-column>
                        <el-table-column prop="coin" label="萝卜币" width="100">
                            <template slot-scope="{ row }"> {{ row.isFuShu ? '-' : '+' }} {{ row.coin }}</template>
                        </el-table-column>
                        <el-table-column prop="statusName" label="状态" width="100"></el-table-column>
                        <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
                    </el-table>
                    <pagination :total="total" :page.sync="page" :limit.sync="pagesize" @pagination="getList"/>
                    <div style="height: 30px"></div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import {getBillListAPI} from './api'

export default {
    name: 'Detail',
    props: {
        detail: {
            type: Object,
            default: () => {
            }
        },
        id: {
            type: Number
        }
    },
    data() {
        return {
            activeName: 'basicInfo',
            list: [],
            total: 0,
            page: 1,
            pagesize: 10
        }
    },

    watch: {
        id() {
            this.getList()
        }
    },

    mounted() {
        this.getList()
    },

    methods: {
        async getList() {
            const params = {page: this.page, pagesize: this.pagesize, uid: this.detail.id}
            const res = await getBillListAPI(params)
            this.list = res.records
            this.total = res.totalRow
        }
    }
}
</script>
<style lang="scss" scoped>
.wrapper /deep/ {
  .user-box {
    display: flex;
    padding: 20px;
    // border-bottom: 1px solid #f5f5f5;
    .user {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;

      .name {
        font-weight: 600;
      }
    }
  }

  .tabs-box {
    padding: 0 20px;
  }

  .card-list {
    display: grid;
    grid-template-columns: repeat(2, 280px);
    gap: 20px;
    justify-content: center;

    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(180deg, #a5a5a5 0%, #7a7a7a 100%);
      height: 140px;
      width: 280px;
      border-radius: 5px;
      padding: 20px;

      .bank,
      .name,
      .no {
        background: linear-gradient(to right, #e2e0e0, #faf1f1);
        /* 从左到右渐变 */
        -webkit-background-clip: text;
        /* Safari/Chrome */
        -webkit-text-fill-color: transparent;
        /* Safari/Chrome */
        color: transparent;
        /* Firefox/Opera */
        font-weight: 600;
      }

      .bank {
        font-size: 18px;
      }

      .name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
      }

      .no {
        font-size: 16px;
      }
    }
  }

  .balance {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    background: #f5f5f5;
    padding: 20px;
    border-radius: 5px;

    .item {
      text-align: center;

      .label {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .value {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .pagination-container {
    padding: 0 20px !important;
  }
}
</style>
