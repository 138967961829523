<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" inline :model="searchData" label-width="">
      <el-form-item label="昵称">
        <el-input style="width: 200px" clearable v-model.trim="searchData.nickname" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input style="width: 200px" clearable v-model.trim="searchData.mobile" size="mini" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="注册时间">
        <el-date-picker
          style="width: 220px"
          v-model="searchData.created_at_range"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态">
        <el-select style="width: 200px" v-model="searchData.is_lock" placeholder="请选择" size="mini" clearable>
          <el-option label="正常" :value="0"> </el-option>
          <el-option label="锁定" :value="1"> </el-option>
          <el-option label="注销" :value="3"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" ">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="getList">搜索</el-button>
        <el-button type="info" size="mini" icon="el-icon-refresh" @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-button style="margin-bottom: 20px" icon="el-icon-download" type="primary" size="mini" plain @click="exportTable">导出</el-button>
    <!-- list -->
    <el-table :data="list">
      <el-table-column prop="nickname" label="昵称" min-width="120"> </el-table-column>

      <el-table-column prop="mobile" label="手机号码" min-width="120"> </el-table-column>
      <el-table-column prop="face" label="状态" min-width="120">
        <template slot-scope="{ row }">
          <el-tag v-if="row.is_lock == 0" type="primary" size="small">{{ row.is_lock_dsc }}</el-tag>
          <el-tag v-if="row.is_lock == 1" type="danger" size="small">{{ row.is_lock_dsc }}</el-tag>
          <el-tag v-if="row.is_lock == 3" type="info" size="small">{{ row.is_lock_dsc }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="邀请人" min-width="180">
        <template slot-scope="{ row }">
          <div class="info-box">
            <div class="row">
              <span class="label">昵称:</span>
              <span> {{ row.invite_user.nickname || '-' }}</span>
            </div>
            <div class="row">
              <span class="label">手机号:</span>
              <span> {{ row.invite_user.mobile || '-' }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="deleted_at" label="注销时间" min-width="120" show-overflow-tooltip>
        <template slot-scope="{ row }">
          {{ row.deleted_at || '-' }}
        </template>
      </el-table-column>
      <el-table-column prop="created_at" label="注册时间" min-width="120" show-overflow-tooltip> </el-table-column>
      <el-table-column label="操作" width="150" fixed="right">
        <template slot-scope="{ row }">
          <el-link style="margin-right: 10px" :type="row.is_lock ? 'primary' : 'danger'" :underline="false" @click="handleStatus(row)">{{
            row.is_lock ? '启用' : '禁用'
          }}</el-link>
          <el-link type="danger" :underline="false" @click="handleDel(row)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" :page.sync="searchData.page" :limit.sync="searchData.page_size" @pagination="getList" />
    <!-- 详情 -->
    <el-drawer title="" size="50%" :withHeader="false" :visible.sync="show_detail" direction="rtl">
      <Detail :detail="detail" :id="detail.id"></Detail>
    </el-drawer>
  </div>
</template>

<script>
import { getListAPI, getDetailAPI, changeStatusAPI, delAPI, DownloadAPI } from './api'
import { authBtnMixin } from '@/mixins/authBtnMixin'
import Detail from './detail.vue'
export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: { Detail },
  data() {
    return {
      show_detail: false,
      list: [],
      searchData: {
        page: 1,
        page_size: 10,
        nickname: '',
        mobile: '',
        created_at_range: [],
        is_lock: ''
      },
      total: 0,
      detail: {}
    }
  },

  watch: {},
  activated() {
    this.getList()
  },
  mounted() {},

  methods: {
    async getList() {
      let params = { ...this.searchData }
      if (this.$route.query.id) {
        params.id = this.$route.query.id
      }
      const res = await getListAPI(params)
      this.list = res.data
      this.total = res.total
    },
    // 详情
    async handleDetail({ id }) {
      this.detail = await getDetailAPI(id)
      this.show_detail = true
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      if (this.$route.query.id) {
        this.$router.push({ name: 'CustomerList' })
        // return
      }
      this.getList()
    },
    handleStatus({ id, is_lock }) {
      let title = is_lock ? '启用' : '禁用'
      const data = { id, is_lock: is_lock ? 0 : 1 }
      this.$confirm(`${title}该账号, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          changeStatusAPI(data).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    handleDel({ id }) {
      this.$confirm('确认删除该用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    exportTable() {
      const { nickname, mobile, created_at_range, is_lock } = this.searchData
      const params = { nickname, mobile, created_at_range, is_lock }
      DownloadAPI(params)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  .info-box {
    .row {
      display: flex;
      align-items: center;
      .label {
        // width: 70px;
        text-align: right;
      }
    }
  }
}
</style>
